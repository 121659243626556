/* eslint-disable @next/next/no-img-element */

import LatestNewsStyles from "../../styles/elements/CustomBlockStyles.module.scss"
import { urlFor, PortableText } from '../../lib/sanity';
import Link from "next/link"

const CustomBlocks = ({Custom}) => {

    return (
      <ul className={LatestNewsStyles.flexblocks}>
        {Custom?.length > 0 &&
          Custom.map((block) => (
            <li className="customcard" key={block._key}>
              <Link href={block.linkaddress ? block.linkaddress : ''}>
                <img src={urlFor(block.image).auto('format').width(450).height(250).url()} alt={block.title} className={LatestNewsStyles.imgclass} width="450" height="250" />
                <h3>{block.title}</h3>
              </Link>
              <p>{block.customblocktext}</p>
            </li>
          ))}
      </ul>
    );
}

export default CustomBlocks
