/* eslint-disable react/display-name */
import { PortableText } from '../../lib/sanity';

import IntroCopyStyles from '../../styles/elements/IntroCopyStyles.module.scss';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const IntroCopyBlock = ({ PageInfo }) => {
  return (
    <div className="copyentry">
      <h1 className={IntroCopyStyles.h1}>{PageInfo.introheading}</h1>
      {PageInfo?.introcopy && <PortableText blocks={PageInfo?.introcopy} serializers={serializers} className="portable-text" />}
    </div>
  );
};

const Heading = ({ children }) => {
  <h2 className="title">{children}</h2>;
};

export default IntroCopyBlock;
