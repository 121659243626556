/* eslint-disable react/display-name */
/* eslint-disable @next/next/no-img-element */

import NurseryStyles from '../../styles/elements/NurseryStyles.module.scss';
import { urlFor, PortableText } from '../../lib/sanity';
import Link from 'next/link';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const Nurseries = ({ Nurseries }) => {
  return (
    <ul className={NurseryStyles.flexblocks}>
      {Nurseries?.length > 0 &&
        Nurseries.map((nursery) => (
          <li className="nurseriescard" key={nursery._id}>
            <Link href={nursery.linkaddress}>
              <h3>{nursery.title}</h3>
              <div>
                <img src={urlFor(nursery.image).auto('format').width(650).height(350).url()} alt={nursery.title} title={nursery.title} className={NurseryStyles.imgclass} width="650" height="350" loading="lazy" />
              </div>
            </Link>
            <div className={NurseryStyles.copyBlock} style={{ backgroundColor: nursery.color }}>
              <PortableText blocks={nursery.description} className="portable-text" serializers={serializers} />
            </div>
          </li>
        ))}
    </ul>
  );
};

export default Nurseries;
