/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';
import Nav from '../navigation/Nav';
import Footer from '../footer/Footer';
import PinkBar from '../Pinkbar';
import LeadingImagePanelHome from '../LeadingImagePanelHome';
import CookieConsent from 'react-cookie-consent';
import { useState } from 'react';


const HomePanelLayout = ({ children, CompanyInfo, PageInfo, hideLeadImage, headerWidth, headerHeight, headerMinHeight, Nurseries }) => {

  const title = `${PageInfo.title} | More2 Nurseries`;

  const [consent, setConsent] = useState(false);
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={PageInfo.meta} />

      </Head>
      <Nav CompanyInfo={CompanyInfo[0]} Nurseries={Nurseries} />
      {hideLeadImage != true && <LeadingImagePanelHome HeaderImage={PageInfo} headerWidth={headerWidth} headerHeight={headerHeight} headerMinHeight={headerMinHeight} />}
      {children}
      {PageInfo.pinkmessage?.length > 0 && <PinkBar message={PageInfo.pinkmessage} />}
      <Footer CompanyInfo={CompanyInfo} Nurseries={Nurseries} />
      <CookieConsent
        location="bottom"
        buttonText="I consent"
        cookieName="more2NurseriesConsent"
        style={{ background: '#6FC4C1', textAlign: 'center' }}
        buttonStyle={{ backgroundColor: '#CD8B9B', color: '#FFFF', fontSize: '13px', fontWeight: 'bold', padding: '0.75rem 2rem', borderRadius: '0.51rem' }}
        acceptOnScrollPercentage={50}
        onAccept={(byScroll) => {
          setConsent(true);
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience. <span style={{ fontSize: '10px' }}></span>
      </CookieConsent>
    </>
  );
};

export default HomePanelLayout;
