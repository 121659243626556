/* eslint-disable react/display-name */
import QuoteStyles from '../styles/elements/QuoteStyles.module.scss';
import { PortableText } from '../lib/sanity';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const GreenQuote = ({ quote }) => {
  return (
    <div className={QuoteStyles.quoteholder}>
      <PortableText blocks={quote} className={QuoteStyles.text} serializers={serializers}></PortableText>
    </div>
  );
};

export default GreenQuote;
