/* eslint-disable @next/next/no-img-element */
import DiscoverMoreStyles from '../../styles/elements/DiscoverMoreStyles.module.scss';
import { urlFor } from '../../lib/sanity';
import Link from 'next/link';

const DiscoverMore = ({ Discover }) => {
  return (
    <ul className={DiscoverMoreStyles.flexblocks}>
      {Discover?.length > 0 &&
        Discover.map((discovery) => (
          <li className={DiscoverMoreStyles.latestnewscard} key={discovery._id}>
            <Link href={discovery.linkaddress}>
              <img src={urlFor(discovery.image).auto('format').url()} alt={discovery.title} title={discovery.title} className={DiscoverMoreStyles.imgclass} loading="lazy" width="150" height="190" />
            </Link>

            <p style={{ color: discovery.color }}>{discovery.title}</p>

            <Link href={discovery.linkaddress}>
              <button className={DiscoverMoreStyles.button} style={{ background: discovery.color }}>
                {discovery.buttontext}
              </button>
            </Link>
          </li>
        ))}
    </ul>
  );
};

export default DiscoverMore;
