/* eslint-disable @next/next/no-img-element */

import LatestNewsStyles from '../../styles/elements/NewsBlockStyles.module.scss';
import { urlFor } from '../../lib/sanity';
import Link from 'next/link';

const News = ({ LatestNews }) => {
  return (
    <ul className={LatestNewsStyles.flexblocks}>
      {LatestNews?.length > 0 &&
        LatestNews.map((news) => (
          <li className="latestnewscard" key={news._id}>
            {news.isexternal != true && (
              <Link href={`/latest-news/${news.slug.current}`}>
                <h3>{news.title}</h3>
                <div className={LatestNewsStyles.imgclip}>
                  <img src={urlFor(news.image).auto('format').width(450).height(250).url()} alt={news.title} title={news.title} className={LatestNewsStyles.imgclass} width="450" height="250" loading="lazy" />
                </div>
              </Link>
            )}

            {news.isexternal == true && (
              <a href={news.externallink} target="_blank" rel="noreferrer">
                <h3>{news.title}</h3>
                <div className={LatestNewsStyles.imgclip}>
                  <img src={urlFor(news.image).width(450).height(250).url()} alt={news.title} title={news.title} className={LatestNewsStyles.imgclass} width="450" height="250" loading="lazy" />
                </div>
              </a>
            )}

            <p>{news.newsintro}</p>

            {news.isexternal != true && (
              <Link href={`/latest-news/${news.slug.current}`}>
                <button className={LatestNewsStyles.button}>Read More</button>
              </Link>
            )}

            {news.isexternal == true && (
              <a href={news.externallink} target="_blank" rel="noreferrer">
                <button className={LatestNewsStyles.button}>Read More</button>
              </a>
            )}
          </li>
        ))}
    </ul>
  );
};

export default News;
