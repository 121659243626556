/* eslint-disable @next/next/no-css-tags */
/* eslint-disable @next/next/no-img-element */
import { useRef, useEffect } from 'react';

import { sanityClient, PortableText } from '../lib/sanity';

import styles from '../styles/Home.module.scss';
import LeadingImagePanelHome from '../components/LeadingImagePanelHome';
import NewsBlocks from '../components/elements/NewsBlocks';
// import DiscoverMore from '../components/elements/DiscoverMore';

import DiscoverMore from '../components/elements/DiscoverMore';
// import Paraloop from '../components/elements/Paraloop';
import NurseryBlocks from '../components/elements/NurseryBlocks';
import CustomBlocks from '../components/elements/CustomBlocks';
import WhiteBlueIllustration from '../components/elements/WhiteBlueIllustration';
import NumberedCopyBlock from '../components/elements/NumberedCopyBlock';
import IntroCopyBlock from '../components/elements/IntroCopyBlock';

/* eslint-disable @next/next/no-img-element */

import Spacer from '../components/elements/Spacer';
import HomePanelLayout from '../components/layouts/HomePanelLayout';
import GreenQuote from '../components/GreenQuote';

const homePageInfoQuery = `*[_id=="df590aee-c2bb-4f82-be1e-e474bc2f2675"] {
  title,
  meta,
  introheading,
  pinkmessage,
  introcopy,
  paragraphs,
  pagequote,
  customblocks,
  headerimage,

  "latestNewsQuery" : *[_type=="latestnews"][0..2] {
    _id,
    title,
    slug,
    isexternal,
    externallink,
    date,
    author,
    image,
    newsintro
  },

  "discoverMoreQuery" : *[_type=="discovermore"] {
    _id,
    title,
    linkaddress,
    externallink,
    image,
    buttontext,
    color,
    discovermoretext
  },
  "nurseriesQuery" : *[_type=="nurseries"] {
    _id,
    title,
    image,
    linkaddress,
    color,
    description,
    buttontext
  },
  "companyInfoQuery" : *[_type=="company"] {
    overlaytitle,
    message,
    title,
    footer,
    sociallinks,
    email,
    numbers
  },
  "nurseryQuery" : *[_type=="nurseries"] {
    address,
    linkaddress,
    "manuscriptURL": manuscript.asset->url,
    mapimage
  },
  "testQuery" : *[_type=="test"] {
    name,
    image
  }
}`;

export default function Home({ HomeInfo }) {
  const PageInfo = HomeInfo[0];

  const discoverMore = PageInfo.discoverMoreQuery;
  const nurseries = PageInfo.nurseriesQuery;
  const lastestNews = PageInfo.latestNewsQuery;
  const Company = PageInfo.companyInfoQuery;
  const Nurseries = PageInfo.nurseryQuery;
  const PageQuote = PageInfo.pagequote;
  const testQuote = PageInfo.testQuote;

  const containerRef = useRef(null);

  useEffect(() => {
    if (window && containerRef.current) {
      window.cloudinary
        .galleryWidget({
          container: containerRef.current,
          cloudName: 'drdi7pcny',
          aspectRatio: '6:4',
          mediaAssets: [
            {
              tag: 'homepage',
              transformation: {
                crop: 'lfill',
                transformation: [{ gravity: 'auto' }]
              }
            }
          ],
          carouselLocation: 'bottom',
          imageBreakpoint: 200,
          borderColor: '#74a2be',
          borderWidth: 4,

          thumbnailProps: {
            width: 95,
            height: 64,
            radius: 4,
            borderColor: '#74a2be',
            borderWidth: 0,
            gutter: 10,
            spacing: 10,
            selectedBorderPosition: 'bottom',
            selectedStyle: 'all',
            selectedBorderWidth: 5,
            transformation: {
              crop: 'fill',
              transformation: [{ gravity: 'auto' }]
            }
          },
          zoomPopupProps: {
            backdropColor: '#335ba5',
            backdropOpacity: 0.8
          },
          zoomProps: {
            type: 'popup',
            level: 2,
            lensOpacity: 0.3
          },
          navigationButtonProps: {
            shape: 'radius',
            size: 60
          },
          themeProps: {
            primary: '#74a2be',
            onPrimary: 'white',
            active: '#e1899b'
          }
        })
        .render();
    }
  });

  return (
    <>
      <HomePanelLayout CompanyInfo={Company} Nurseries={Nurseries} PageInfo={PageInfo} headerWidth={1920} headerHeight={600} headerMinHeight={300} hideLeadImage={false}>
        <div className={styles.mainContent}>
          <div className="container" style={{ paddingTop: `1rem` }}>
            <IntroCopyBlock PageInfo={PageInfo} />
            <Spacer mt={2} />

            <div ref={containerRef} style={{ width: '100%', margin: '0', padding: '0' }} />
            <Spacer mt={4} />

            <h2 style={{ textAlign: `center`, fontSize: `2rem`, marginBottom: `0.5em` }}>Latest News</h2>
            <NewsBlocks LatestNews={lastestNews} />
            <Spacer mt={4} />
            <DiscoverMore Discover={discoverMore} />
            <Spacer mt={4} />

            <NumberedCopyBlock PageInfo={PageInfo} paragraphNumber={0} />

            <Spacer mt={2} />

            <NurseryBlocks Nurseries={nurseries} />

            <NumberedCopyBlock PageInfo={PageInfo} paragraphNumber={1} marginTop={4} />
            <Spacer mt={4} />

            <div className="homecustomPad">
              <CustomBlocks Custom={PageInfo.customblocks} />
            </div>

            <NumberedCopyBlock PageInfo={PageInfo} paragraphNumber={2} />
          </div>
        </div>
        {PageQuote && <GreenQuote quote={PageQuote} />}
        <WhiteBlueIllustration />
      </HomePanelLayout>
    </>
  );
}

export async function getStaticProps() {
  const HomeInfo = await sanityClient.fetch(homePageInfoQuery);
  return { props: { HomeInfo } };
}

/* <Paraloop CopyLoop={PageInfo.paragraphs} /> */
