/* eslint-disable react/no-unknown-property */
/* eslint-disable react/display-name */
/* eslint-disable @next/next/no-img-element */

import NumberedCopyBlockStyle from '../../styles/elements/NumberedCopyBlock.module.scss';

import { PortableText, urlFor } from '../../lib/sanity';
import Spacer from './Spacer';

const serializers = {
  marks: {
    link: ({ children, mark }) =>
      mark.blank ? (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={mark.href}>{children}</a>
      )
  }
};

const NumberedCopyBlock = ({ PageInfo, paragraphNumber, marginTop }) => {
  return (
    <>
      <div
        style={{ marginTop: `${marginTop}rem` }}
        className={(function () {
          switch (PageInfo.paragraphs[`${paragraphNumber}`].display) {
            case 'larger':
              return 'largertext copyentry';
            case 'background':
              return 'greenback copyentry';
            default:
              return 'copyentry';
          }
        })()}
      >
        <h3 className={NumberedCopyBlockStyle.sectionHeading}>{PageInfo.paragraphs[`${paragraphNumber}`].title}</h3>

        <PortableText blocks={PageInfo.paragraphs[`${paragraphNumber}`].paragraphcopy} className="portable-text" serializers={serializers} />

        {PageInfo.paragraphs[`${paragraphNumber}`]?.image && (
          <>
            <Spacer mt={2} />
            <img src={urlFor(PageInfo.paragraphs[`${paragraphNumber}`].image).auto('format').width(1440).url()} alt={PageInfo.paragraphs[`${paragraphNumber}`].title} className={NumberedCopyBlockStyle.imgclass} width="960" serializers={serializers} />
          </>
        )}
      </div>
    </>
  );
};

NumberedCopyBlock.defaultProps = {
  marginTop: '2'
};

export default NumberedCopyBlock;
